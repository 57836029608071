class PhoneCTA {
	constructor(settings) {

		const defaultSettings = {
			overlay: document.querySelector('.cta-overlay'),
			formWrapper: document.querySelector('.cta-form-wrapper-all'),
			hideCtaBtn : '.cta-hide-tooltip',
			showCtaBtn : '.phone-icon',
			ctaWrapper : document.querySelector('.cta-open-wrapper'),
			openButton: '.cta-open, .open-cta-forms',
			closeButton: '.close-cta-overlay',
			formSwitchers: '.form-picker',
			ctaForms: '.cta-form'
		};

		// decides between default settings or passed args on constructor
		this.settings = arguments.length < 1 ? defaultSettings : settings;

		// this.debugSettings();

		// Open button bind
		this.addEvent(this.settings.openButton, 'click', this.openModal.bind(this));

		// Close button bind
		this.addEvent(this.settings.closeButton, 'click', this.closeModal.bind(this));

		// Form Switchers
		this.addEvent(this.settings.formSwitchers, 'click', this.formSwitcher.bind(this));

		// hide / show tooltip
		this.addEvent(this.settings.hideCtaBtn, 'click', this.toggleCTA.bind(this));
		this.addEvent(this.settings.showCtaBtn, 'click', this.toggleCTA.bind(this));
	}


	/**
	 * @method debugSettings
	 * Logs settings currently being used
	*/

	debugSettings() {
		console.log(this.settings);
	}

	/**
	 * @method AddEvent
	 * @param {DomTokenNode | DomTokenList} element // Nodes to bind event
	 * @param {String} event  // Event to bind
	 * @param {String | Function} func // function to use on event
	 *
	 * Helper method to bind events;
	*/

	addEvent(element, event, func) {

		if (element !== window) {
			element = document.querySelectorAll(element);

			element.forEach(el => {
				return el.addEventListener(event, func);
			});
		} else {
			return element.addEventListener(event, func);
		}

	}

	/**
	 * @param {DOMTokenNode} el Element to apply
	 * @param {Function} cb Callback Function
	 * @see https://gist.github.com/alirezas/c4f9f43e9fe1abba9a4824dd6fc60a55
	 * FadeOut polyfill for vanilla JS
	*/

	fadeOut(el, cb) {
		el.style.opacity = 1;

		(function fade() {
			if ((el.style.opacity -= .1) < 0) {
				el.style.display = "none";
			} else {
				requestAnimationFrame(fade);
			}
		})();

		if (typeof cb === 'function') {
			cb();
		}
	}

	/**
	 * @param {DOMTokenNode} el Element to apply
	 * @param {String} display What display property should be applied to the element after fading in. Defaults to Block
	 * @param {Function} cb Callback Function
	 * @see https://gist.github.com/alirezas/c4f9f43e9fe1abba9a4824dd6fc60a55
	 * FadeIn polyfill for vanilla JS
	*/

	fadeIn(el, display, cb) {
		el.style.opacity = 0;
		el.style.display = display || "block";

		(function fade() {
			var val = parseFloat(el.style.opacity);
			if (!((val += .1) > 1)) {
				el.style.opacity = val;
				requestAnimationFrame(fade);
			}
		})();

		if ( typeof cb === 'function' ) {
			cb();
		}
	}

	openModal() {
		this.fadeIn(this.settings.overlay);
	}

	closeModal() {
		this.fadeOut(this.settings.overlay);
	}

	formSwitcher(event) {
		// Prevent clicks on <i> icon missing instance data;
		let target = event.target.nodeName === 'I' ? event.target.parentNode : event.target;
		let instance = target.dataset.instance;
		let forms = document.querySelectorAll(this.settings.ctaForms);
		let targetedForm = document.querySelector(`${this.settings.ctaForms}[data-instance="${instance}"]`);
		let switchers = document.querySelectorAll(this.settings.formSwitchers);


		forms.forEach(switcher => {
			this.fadeOut(switcher);
		});

		document.querySelector(`${this.settings.formSwitchers}.active`).classList.remove('active');
		target.classList.add('active');

		window.setTimeout( () => {
			this.fadeIn(targetedForm);
		}, 400);

	}

	toggleCTA() {
		this.settings.ctaWrapper.classList.toggle('cta-shown');
	}

}

const helpers = {
	wpVars : typeof wpVars !== 'object' ? JSON.parse(wpVars) : wpVars
}


/**
 * @method fetchResults
 * @param {String} url | URL to fetch
 * @param {String} actionName | WP action name
 * @param {Object} body | POST body if exists
 * @description Fetch helper for wordpress
*/

const fetchResults = (url, actionName, body) => {
	const config = {
		method: typeof body === undefined || null ? 'GET' : 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
		},
		credentials: 'same-origin'
	}

	if (body) {
		config.body = JSON.stringify(body)
	}

	let request = fetch(`${url}?action=${actionName}`, config)
		.then(response => response.json())

	return request;
}

const sendMail = e => {
	e.preventDefault();

	const { adminURL } = wpVars;

	const fData = new FormData(e.target);
	let fieldJSON = {};

	// From array to JSON
	for (var pair of fData.entries()) {
		fieldJSON[pair[0]] = pair[1];
	}

	let btn = e.target.querySelector('button');

	btn.disabled = true;
	let oldText = btn.textContent;
	btn.textContent = 'ENVIANDO...';

	let submit = fetchResults(adminURL, 'hiro_submit_emails', fieldJSON);

	submit.then(res => {
		if (res.status === 'success') {
			Sweetalert2.fire({
				'icon': 'success',
				'title': 'Obrigado!',
				'text': 'Sua mensagem foi enviada com sucesso.',
				'footer': 'Retornaremos o mais breve possível ou no horário pedido.'
			});
			ga('gtag_UA_61674501_1.send', 'event', 'Formulário', 'Envio', 'Formulário');
		} else {
			Sweetalert2.fire({
				'icon': 'error',
				'title': 'Ops!',
				'text': 'Hove um erro no envio da sua mensagem.',
				'footer': 'Por favor tente novamente mais tarde ou entre em contato através de um de nossos telefones.'
			});
		}

		btn.disabled = false;
		btn.textContent = oldText;
	});
}

addEventListener('load', () => {
	let CTA = new PhoneCTA();

	let forms = document.querySelectorAll('form');

	forms.forEach(form => {
		form.addEventListener('submit', sendMail);
	}); 


	function t(t, e, n) {
        var o = n.target
          , a = o.value.replace(/\D/g, "")
          , r = o.value.length > e ? 1 : 0;
        VMasker(o).unMask(),
        VMasker(o).maskPattern(t[r]),
        o.value = VMasker.toPattern(a, t[r])
	}
	
	var e = ["(99) 9999-99999", "(99) 99999-9999"];
    document.querySelectorAll('input[name="Telefone"], input[name="Celular"], input[name="your-subject"]').forEach(n=>{
        VMasker(n).maskPattern(e[0]),
        n.addEventListener("input", t.bind(void 0, e, 14), !1)
	});
	
});